import React from 'react';
import PropTypes from "prop-types";
import { SectionWrapper,CheckMark, Arrow, InterestTile, SportTile, StyledCheckBox, TileTitle } from './styles';
import { WaterSports, BallSports, WheelSports, WinterSports, CombatSports, ResistanceTraining } from '../SvgComponents';
import { ImageUrl } from '../../utils/constants';
import { withTranslation } from 'react-i18next';
import LazyImage from '../common/LazyImage/LazyImage';

const Components = {
  WaterSports: WaterSports,
  BallSports: BallSports,
  WinterSports: WinterSports,
  WheelSports: WheelSports,
  CombatSports: CombatSports,
  ResistanceTraining: ResistanceTraining
};

class RegistrationEdit extends React.Component {
  constructor() {
    super();
    this.state = {
      openedSection: false,
      checkData: []
    };
  }

  openList = () => {
    this.setState({
      openedSection: !this.state.openedSection
    })
  };

  getComponentName = (title) => {
    if(title === 'other') {
      return '';
    } else {
      let UpdatedTitle = title.replace(
        /\w\S*/g,
        function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
      return Components[UpdatedTitle.replace(/ /g, '')];
    }
  };

  renderWellnessInterests = () => {
    const { list, id, title, checkData, onChangeText, onCheckboxData, interest, t } = this.props;
    const ComponentName = this.getComponentName(id);

    return (
      <div>
        <InterestTile onClick={() => this.openList()} isIndividualSport={id === 'individual sports'}>
          {
            interest && (
              id === 'other' ? <LazyImage src={ImageUrl + "/images/other-sports.png"} /> : (
                id === 'individual sports' ? <LazyImage src={ImageUrl + "/images/aoi_icons/individualSports.png"} /> : <ComponentName color="#003333" />
              )
            )
          }
          <TileTitle smallLine={title === 'other'}>{title}</TileTitle>
          <Arrow active={this.state.openedSection} />
        </InterestTile>
        {(this.state.openedSection) &&
          list.map((listData, index) => {
            if( id.toLowerCase().trim() === (interest ? listData.interest_type.toLowerCase().trim() : listData.goal_type.toLowerCase().trim())) {
              return (
                <SportTile key={index}>
                  <StyledCheckBox>
                    <span>{t(listData.name)}</span>
                    <input
                      type="checkbox"
                      checked={checkData.includes(listData.id)}
                      onChange={() => onCheckboxData(onChangeText, listData.id)}
                    />
                    <CheckMark checked={checkData.includes(listData.id)}/>
                  </StyledCheckBox>
                  { interest &&
                    <img src={`${ImageUrl}/${listData.interest_image}`} />
                  }
                </SportTile>
              )
            }
          })
        }
      </div>
    )    
  };

  render(){
    
    return(
      <SectionWrapper Edit>
        {this.renderWellnessInterests()}
      </SectionWrapper>
    )
  }
}

RegistrationEdit.propTypes = {
  list: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  checkData: PropTypes.array.isRequired,
  onCheckboxData: PropTypes.func.isRequired,
  onChangeText: PropTypes.string,
  interest: PropTypes.bool,
  t: PropTypes.func
};

export default (withTranslation()(RegistrationEdit));