/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import { Wrapper, Arrow, InterestTile, SectionWrapper, SportTile, StyledCheckBox, Button, ButtonContainer, TileTitle, TilesDiv, CheckMark2 } from '../RegistrationWellnessInterests/styles';
import { FormContainer, HeaderBox, Title } from '../RegistrationForm/styles';

import { getLongTermGoals, addLongTermGoals, getFitnessGoals } from '../../redux/actions';

import Waiting from '../Waiting';
import FormWizardSteps from '../FormWizardSteps';
import { withTranslation } from 'react-i18next';

const FirstColumnSports = [
  'health',
  'behavioral',
];

const SecondColumnSports = [
  'performance',
  'racing/competition'
];

class RegistrationFitnessGoals extends Component {
  constructor() {
    super();
    this.state = {
      openedSectionArray: [""]
    };
  }

  componentDidMount() {
    const { getLongTermGoals, longTermGoals, fetchFitnessGoals, fitnessGoals, areaOfInterest, history, companyId } = this.props;
    if (companyId < 0) {
      history.push('/user');
    }
    if (!longTermGoals) getLongTermGoals();
    if (!fitnessGoals) fetchFitnessGoals();
    if (areaOfInterest) {
      if (!areaOfInterest.length) {
        history.push('/user/step2')
      }
    }
  }
  showRelatedSportsV2 = (sportTitle) => {
    const { openedSectionArray } = this.state;
    if (openedSectionArray.includes(sportTitle)) {
      let newData = openedSectionArray.filter((obj) => obj !== sportTitle)
      this.setState({ openedSectionArray: newData })
    } else {
      let prevData = openedSectionArray
      prevData.push(sportTitle)
      this.setState({ openedSectionArray: prevData })
    }
  };
  onChange = (sportId) => {
    const { addLongTermGoals, longTermGoals } = this.props;
    if (longTermGoals.includes(sportId)) {
      addLongTermGoals(sportId);
    }
    else {
      if (longTermGoals.length < 5) {
        addLongTermGoals(sportId);
      }
    }
  };

  renderWellnessInterests = (sports) => sports.map((sport) => {
    const { fitnessGoals, longTermGoals } = this.props;

    let array = [];
    fitnessGoals.map((goal) => {
      if (goal.goal_type.toLowerCase().trim() === sport.toLowerCase().trim()) {
        array.push(goal);
      }
    });
    return (
      <div key={sport}>
        <InterestTile onClick={() => this.showRelatedSportsV2(sport)}>
          <TileTitle smallLine={sport === 'other'}>{sport}</TileTitle>
          <Arrow active={(this.state.openedSectionArray.includes(sport))} />
        </InterestTile>
        {(this.state.openedSectionArray.includes(sport)) &&
          <TilesDiv>

            {array.map((data) => (
              <SportTile key={data.name} onClick={() => this.onChange(data.id)}>
                <span>{data.name}</span>

                <StyledCheckBox>
                  <input
                    type="checkbox"
                    checked={longTermGoals.includes(data.id)}
                    onChange={() => this.onChange(data.id)}
                  />
                  <CheckMark2 checked={longTermGoals.includes(data.id)} />
                </StyledCheckBox>
              </SportTile>
            ))}
          </TilesDiv>
        }
      </div>
    )
  });

  saveLongTermGoals = () => {
    const { history } = this.props;
    history.push('/user/step4');
  };

  render() {
    const { history, fitnessGoals, fitnessError, longTermGoals, t } = this.props;

    if (!fitnessGoals && !fitnessError) {
      return <Waiting />
    }

    return (
      <Wrapper>
        <FormWizardSteps history={history} activeStep={3} />
        <Title>{t("Wellness and Fitness Goals")}</Title>

        <FormContainer >
          <HeaderBox>
            <h1>{t("Wellness and Fitness Goals")}</h1>
            <h3>{("Upto 5 choices Allowed Across All Categories in Total.")}</h3>
          </HeaderBox>
          {
            (!fitnessGoals && !fitnessError) ? <Waiting /> :
              <div>
                <SectionWrapper>
                  {this.renderWellnessInterests(FirstColumnSports)}
                  {this.renderWellnessInterests(SecondColumnSports)}
                </SectionWrapper>
              </div>
          }
          <ButtonContainer>
            <Button color={'rgba(105, 194, 255, 0.1)'} onClick={() => history.push('/user/step2')}>
              <i className="fas fa-chevron-left" />
              {t("Go Back")}
            </Button>
            <Button onClick={this.saveLongTermGoals} disabled={longTermGoals.length < 1}>
              {t("Next")}
              <i className="fas fa-chevron-right" />
            </Button>
          </ButtonContainer>
        </FormContainer>


      </Wrapper>
    )
  }
}

RegistrationFitnessGoals.propTypes = {
  history: PropTypes.object.isRequired,
  getLongTermGoals: PropTypes.func.isRequired,
  addLongTermGoals: PropTypes.func.isRequired,
  longTermGoals: PropTypes.array.isRequired,
  fetchFitnessGoals: PropTypes.func.isRequired,
  fitnessError: PropTypes.string,
  fitnessGoals: PropTypes.array,
  areaOfInterest: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func

};

const mapStateToProps = (state) => ({
  longTermGoals: state.register.long_term,
  fitnessGoals: state.register.fitnessGoals,
  fitnessError: state.register.fitnessError,
  areaOfInterest: state.register.aoi
});

const mapDispatchToProps = (dispatch) => ({
  fetchFitnessGoals: () => dispatch(getFitnessGoals()),
  getLongTermGoals: () => dispatch(getLongTermGoals()),
  addLongTermGoals: (longTermGoals) => dispatch(addLongTermGoals(longTermGoals))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegistrationFitnessGoals));
