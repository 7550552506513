import styled, { css } from 'styled-components';


const BoldFont = 'Rubik-Bold';

const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  background:#f3f6f9
  @media (max-width: 600px) {
  max-width: 100%;

  }
`;
const CustomCheckbox = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: ${({ importantBottom }) => importantBottom ? '0px !important' : '10px'};
  margin-bottom: ${({ importantBottom }) => importantBottom ? '0px !important' : '10px'};
  margin-right: 20px;
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  font-weight: normal;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  float: left;
  text-align: left;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;

    > input {
      cursor: not-allowed;
    }
  `}
  
  @media (max-width: 767px) {
    margin-right: 10px;
  }
  
  ${({ hidden }) => hidden && css`
    display: none;
  `}
  
  > span {
    color: black;
  }
`;


const Arrow = styled.span`
  border-bottom: 5px solid transparent;
  border-left: 7px solid #159fc9;
  border-top: 5px solid transparent;
  float: left;
  cursor: pointer;
  float: right;
  margin-top: ${({ isOtherSports }) => isOtherSports ? '5px' : '12px'};
  
  ${({active}) => active && css`
    border-left: 5px solid transparent;
    border-top: 7px solid #159fc9;
    border-right: 5px solid transparent;
  `}
`;

const InterestTile = styled.button`
  width: 100%;
  float: left;
  height: 52px;
  background-color: #e2f2f8;
  padding: 0 25px 0 20px;
  margin-top: 5px;
  cursor: pointer;
  border: none;

  &:active, &:hover, &:focus {
    outline: none;
  }
  
  > svg {
    margin-right: 5px;
  }
  div{
    > img {
    float: left;
    margin-right: 10px;
  }

  }
  
  > img {
    float: left;
    margin-right: 10px;
  }

  ${({ isIndividualSport }) => isIndividualSport && css`
  div{
    > img {
      width: 20px;
      margin-top: 5px;
    }

  }
    > img {
      width: 20px;
      margin-top: 5px;
    }
  `}
`;

const TileTitle = styled.span`
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  font-family: ${BoldFont};
  text-transform: capitalize;
  float: left;
  line-height: ${({smallLine}) => smallLine ? '21px' : '30px'};
`;

const SectionWrapper = styled.div`
  width: ${({Edit}) => Edit ? '100%' : '48%'};
  float: ${({Edit}) => Edit ? 'left' : 'right'};
  padding: ${({Edit}) => Edit ? '10px 0px' : '20px'};
  
  &:first-child {
    float: left;
  }
  
    
  @media (max-width: 767px) {
    width: 80%;
    float: left;
    margin: 0 10%;
    padding: 0 20px;
  }
  
  @media (max-width: 500px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const SportTile = styled.div`
  width: 100%;
  float: left;
  height: 60px;
  border: none;
  border-bottom: 1px solid #cdcbcb;
  padding: 0 25px 0 20px;
  background-color: white;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  
  > span {
    font-size: 14px;
    letter-spacing: 0px;
    color: rgb(153,153,153);
    text-transform: capitalize;
  }
  
  &:last-child {
    border-bottom: none;
  }
  
  &:first-child {
    margin-top: 5px;
  }

  > img {
    width: 22px;
    height: 22px;
    float: right;
  }
`;

const StyledCheckBox = styled(CustomCheckbox)`
  margin-top: ${({ top }) => top && top };
  > span {
    line-height: 23px;
    text-transform: capitalize;
    letter-spacing: 0px;
    color: rgb(153,153,153);
  }
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
  float: left;
  width: 100%;
  padding: 0 20px;
  
  @media (max-width: 500px) {
    padding: 0;
  }
`;


const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #cdcbcb;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};

  &::after {
    content: "";
    position: absolute;
    display: block;
    left: 8px;
    top: 2px;
    width: 6px;
    height: 13px;
    border-style: solid;
    border-color: ${({ checked }) => checked ? '#159fc9' : 'white'};
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }
`;


export { Wrapper,CheckMark, Arrow, InterestTile, SectionWrapper, SportTile, StyledCheckBox, ButtonContainer, TileTitle };